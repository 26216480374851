import type { ReactNode } from "react"

let className = "mb-2 mt-2"
export const pClassName = className

export function H1({ children }: { children: ReactNode }) {
  return <h1 className="text-bold text-3xl">{children}</h1>
}

export function SectionTitle({ children }: { children: ReactNode }) {
  return (
    <h2
      className="mb-2 text-xl font-semibold
        lg:mb-4 lg:text-2xl"
    >
      {children}
    </h2>
  )
}

export function Section({
  children,
  dark,
}: {
  children: ReactNode
  dark?: boolean
}) {
  return (
    <FullWidthSection dark={dark}>
      <div className="mx-auto w-full max-w-prose">{children}</div>
    </FullWidthSection>
  )
}

export function FullWidthSection({
  children,
  dark,
}: {
  children: ReactNode
  dark?: boolean
}) {
  return (
    <div
      className={`${dark ? "bg-gray-200" : ""}
        py-4 px-8 max-sm:px-4 lg:py-8
        `}
    >
      {children}
    </div>
  )
}

export function P({ children }: { children: ReactNode }) {
  return <p className="mb-2 indent-8 lg:text-justify">{children}</p>
}
